import { OverrideStyleConfig } from '@pv-frontend/pv-shared-components/common/types';

// TODO: need to move the interface to one place
export const navBarStyles: {
  container: OverrideStyleConfig;
  backIcon: OverrideStyleConfig;
} = {
  container: {
    boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.16)',
    backgroundColor: 'var(--neutral-white)',
    fontWeight: '700',
    fontSize: '17px',
    padding: '0.8rem 1rem',
  },
  backIcon: {
    width: '32px',
    height: '32px',
    margin: '0 auto',
    fill: 'var(--primary-color)',
    boxShadow: '0px 1px 3px 0px var(--card-shadow)',
    padding: '6px 8px',
    borderRadius: '50%',
  },
};
