import { CardStyleOverrideConfig } from '@pv-frontend/pv-shared-components/card';

export const offerCardStyles: CardStyleOverrideConfig = {
  points: {
    container: {
      borderRadius: '74px',
      border: '0.5px solid var(--neutral-black-1)',
      background: 'var(--neutral-white)',
      backdropFilter: 'blur(2px)',
      padding: '2px 8px 4px',
      marginTop: '-10px',
      display: 'none',
    },
    label: {
      fontSize: '11px',
      margin: '2px 0 0 2px',
    },
    icon: {
      display: 'block',
    },
  },
  avatar: {
    imageOrInitialsContainer: {
      image: {
        width: '100%',
        height: '100%',
        boxShadow: '0px 5px 1px 0px rgba(0, 0, 0, 0.10)',
      },
    },
  },
  divider: {
    opacity: '.4',
  },
  cardContainer: {
    width: '100% !important',
    height: '100% !important',
    borderRadius: '1.143rem',
    position: 'relative',
    boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.16)',
  },
  cardContent: {
    borderRadius: '0 0 .7rem .7rem',
    // border: '.4px solid var(--surface-border-subtle)',
    padding: ' 0.571rem 0.857rem 0.571rem 0.857rem',
  },
  image: {
    minHeight: '107px',
    borderRadius: '0.7rem 0.7rem 0 0',
  },
  actionButton: {
    textTransform: 'uppercase',
    margin: '0',
  },
  title: {
    fontWeight: '600',
    fontSize: '13px',
    color: 'var(--text-color)',
  },
  subTitle: {
    color: 'var(--gray-secondary)',
  },
  information: {
    baseText: {
      fontSize: '12px',
      color: 'var(--primary-color)',
    },
    attentionText: {
      fontSize: '12px',
      color: 'var(--primary-color)',
    },
  },
};
