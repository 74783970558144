import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';

import { BannersResponse } from './../interfaces/common.interfaces';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  public constructor(private http: HttpClient) {}

  public fetchCampaignBanners(): Observable<BannersResponse> {
    return this.http.get<BannersResponse>(
      `${environment.campaignPath}/campaigns/banners`
    );
  }
}
