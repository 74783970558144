<main class="overflow-x-hidden">
  <p-toast
    position="top-right"
    [breakpoints]="{ '1024px': { right: '0' } }"></p-toast>
  <pv-header *ngIf="showHeader"></pv-header>
  <pv-authentication [isVisible]="showAuthenticationModal"></pv-authentication>
  <router-outlet></router-outlet>
  <pv-drawer
    [isVisible]="showDrawer"
    position="bottom"
    [showCloseIcon]="false"
    [headerTemplate]="headerTemplate"
    [contentTemplate]="contentTemplate"
    [footerTemplate]="footerTemplate"
    [showBackdrop]="true"
    [dismissOnBackdropClick]="true"
    [closeOnEscape]="true"
    styleClass="custom-modal"
    [style]="pvDrawerStyleOverrides"
    (closeHandler)="handleClose()">
    <!-- header template is implemented only for user cards listing as this has sticky header label with usercards scrollable -->
    <ng-template #headerTemplate></ng-template>
    <ng-template #contentTemplate>
      <cx-contextual-details
        [campaignId]="campaignId"
        [campaignRedemptionType]="campaignRedemptionType"
        [benefitID]="selectedBenefit.benefitId"
        [benefitType]="selectedBenefit.benefitType"
        (emitshowSuccessModal)="handleSuccessModal()"
        (knowMoreClick)="handleClose()"></cx-contextual-details>
    </ng-template>
    <ng-template #footerTemplate></ng-template>
  </pv-drawer>
</main>
