import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { PVHeaderModule } from '@pv-frontend/header';

@Component({
  selector: 'jana-terms',
  standalone: true,
  imports: [CommonModule, PVHeaderModule],
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss'],
})
export class TermsComponent {}
