import { Inject, Injectable } from '@angular/core';
import {
  PvUserService,
  PV_USER_SERVICE,
} from '@pv-frontend/pv-shared-services/pv-user-service';

import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  public constructor(
    @Inject(PV_USER_SERVICE) public pvUserService: PvUserService
  ) {}
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public generateQueryString(obj: Record<string, any>): string {
    const str = [];
    for (const key in obj)
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        str.push(encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]));
      }
    return str.join('&');
  }

  public getProjectIdFromSegment(): string {
    const segment = this.pvUserService.getUserDetails()?.segment?.code;
    if (segment === 'EXCLUSIVE') return environment.exclusiveProjectId;
    else if (segment === 'PREMIER') return environment.premierProjectId;
    else return environment.regularProjectId;
  }
}
