import { Component, Inject } from '@angular/core';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import {
  BenefitTypes,
  CampaignRedemptionType,
} from '@pv-frontend/contextual-journey';
import { BannerData } from '@pv-frontend/gift-voucher-journey/home/home.interface';
import { OverrideStyleConfig } from '@pv-frontend/pv-shared-components/common/types';
import { UserDetails } from '@pv-frontend/pv-shared-services/common';
import {
  JourneyMetadataService,
  JOURNEY_METADATA_SERVICE_TOKEN,
} from '@pv-frontend/pv-shared-services/journey-metadata-service';
import {
  PV_USER_SERVICE,
  PvUserService,
} from '@pv-frontend/pv-shared-services/pv-user-service';
import { Subject, takeUntil } from 'rxjs';

import { giftVouchersConfig } from '../const/gift-vouchers';
import { giftVouchersDesktopConfig } from '../const/gift-vouchers-desktop';

import { BENEFIT_TYPE_MAPPING } from './app.constants';
import {
  CampaignBanner,
  MerchantInfo,
  ContextualDrawerData,
  BannersResponse,
} from './interfaces/common.interfaces';
import { ApiService } from './services/api.service';
import { CommonService } from './services/common.service';

@Component({
  selector: 'jana-app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  private readonly PATH_INFO = 'journey.landingPage.banners.config.data';
  private destroy$: Subject<void> = new Subject<void>();
  public isLoggedIn: boolean;
  public showDrawer: boolean = false;
  public campaignId: string = '';
  public showHeader: boolean = false;
  public showAuthenticationModal: boolean = false;
  public selectedBenefit: {
    benefitId: string;
    benefitType?: BenefitTypes;
  } = {
    benefitId: '',
  };
  public userDetails: UserDetails | null = null;
  public points: number = 0;
  public ctaBtn: OverrideStyleConfig = {
    background: 'var(--jana-background-accent-primary)',
    borderRadius: '32px',
    boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.16)',
    border: '2px solid rgba(8, 69, 68, 0.00)',
    zIndex: '1',
    color: 'var(--neutral-white)',
    fontWeight: '400',
    fontSize: '10px',
  };
  public pvDrawerStyleOverrides: OverrideStyleConfig = {
    height: '65%',
    borderTopRightRadius: '15px',
    borderTopLeftRadius: '15px',
    overflow: 'hidden',
    padding: '0px',
  };
  public campaignRedemptionType: CampaignRedemptionType =
    'existing_perks_incentive';
  public title: unknown;
  private staticBanner: { mobile: BannerData[]; desktop: BannerData[] } = {
    mobile: giftVouchersConfig?.landingPage?.banners?.config.data || [],
    desktop:
      giftVouchersDesktopConfig?.landingPage?.banners?.config?.data || [],
  };
  public constructor(
    @Inject(PV_USER_SERVICE) private pvUserService: PvUserService,
    private apiService: ApiService,
    private route: ActivatedRoute,
    private router: Router,
    private commonService: CommonService,
    @Inject(JOURNEY_METADATA_SERVICE_TOKEN)
    public journeyMetadataService: JourneyMetadataService
  ) {
    this.isLoggedIn = this.pvUserService.isUserAuthenticated();
    if (this.isLoggedIn) {
      this.getCampaignBanners();
      this.userDetails = this.pvUserService.getUserDetails();
      this.points = this.pvUserService.getUserPoints();
    }
    this.pvUserService.userLoggedInObservable$
      .pipe(takeUntil(this.destroy$))
      .subscribe((isLoggedIn: boolean) => {
        this.isLoggedIn = isLoggedIn;
        this.userDetails = this.pvUserService.getUserDetails();
      });
    this.pvUserService.loginModalObservable$
      .pipe(takeUntil(this.destroy$))
      .subscribe((showAuthenticationModal: boolean) => {
        this.showAuthenticationModal = showAuthenticationModal;
      });
    this.subsribeToQueryParams();
    this.checkIfContextualRoute();

    this.pvUserService.userLoggedInObservable$.subscribe((res: boolean) => {
      if (res) this.getCampaignBanners();
    });
  }

  private getCampaignBanners(): void {
    this.apiService.fetchCampaignBanners().subscribe((res: BannersResponse) => {
      if (!res?.data?.length) {
        this.updateBanners(false);
      }
      res.data.forEach((banner: CampaignBanner) => {
        const redemptionOptionType: keyof MerchantInfo = (
          Object.keys(banner.attributes.merchant_info) as (keyof MerchantInfo)[]
        )[0];
        const queryParams: ContextualDrawerData = {
          benefitId:
            banner.attributes.merchant_info[redemptionOptionType]?.[0]
              .redeemable_id,
          benefitType: BENEFIT_TYPE_MAPPING[redemptionOptionType],
          campaignId: banner.attributes.campaign_id,
          campaignRedemptionType:
            banner.attributes.campaign_incentives?.data.type,
        };
        const bannerData: BannerData = {
          imageUrl: banner.attributes.campaign_in_app_banner ?? '',
          redirectUrl:
            window.innerWidth < 768
              ? `?${this.commonService.generateQueryString(queryParams)}`
              : `contextual-giftcards-details/${queryParams.benefitId}?${this.commonService.generateQueryString({ c_id: queryParams.campaignId, campaignRedemptionType: queryParams.campaignRedemptionType })}`,
        };
        this.updateBanners(true, [bannerData]);
      });
    });
  }

  private subsribeToQueryParams(): void {
    this.route.queryParams.subscribe((queryParams) => {
      if (queryParams['campaignId']) {
        this.campaignId = queryParams['campaignId'];
        this.selectedBenefit = {
          benefitId: queryParams['benefitId'],
          benefitType: queryParams['benefitType'],
        };
        this.campaignRedemptionType = queryParams['campaignRedemptionType'];
        this.showDrawer = true;
      } else {
        this.showDrawer = false;
      }
    });
  }

  public handleClose(): void {
    this.showDrawer = false;
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        campaignId: null,
      },
    });
  }

  private updateBanners(
    isCampaignAvailable: boolean,
    campaignBannerData: BannerData[] = []
  ): void {
    if (window.innerWidth < 768) {
      if (giftVouchersConfig.landingPage.banners) {
        giftVouchersConfig.landingPage.banners.config.data = isCampaignAvailable
          ? campaignBannerData
          : this.staticBanner.mobile;
      }
    } else {
      if (giftVouchersDesktopConfig.landingPage.banners) {
        giftVouchersDesktopConfig.landingPage.banners.config.data =
          isCampaignAvailable ? campaignBannerData : this.staticBanner.desktop;
      }
    }
  }

  private checkIfContextualRoute(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.showHeader = event.url.includes('contextual-know-more');
        if ((event.url === '/' || event.url === '/gvms') && this.isLoggedIn) {
          this.getCampaignBanners();
        }
      }
    });
  }

  public navigateToEarnMore(): void {
    this.router.navigate(['/earn-more']);
  }

  public handleSuccessModal(): void {
    if (this.pvDrawerStyleOverrides) {
      this.pvDrawerStyleOverrides['height'] = '1%';
    }
  }
}
