import { ChipStyleOverride } from '@pv-frontend/pv-shared-components/chip';

export const chipsStyles: {
  inActive: ChipStyleOverride;
  active: ChipStyleOverride;
} = {
  inActive: {
    container: {
      width: 'auto',
      whiteSpace: 'nowrap',
      border: 'unset',
      borderRadius: '2.286rem',
      color: 'var(--surface-text-subdued-low-contrast)',
      backgroundColor: 'var(--jana-background-accent-light-primary)',
      margin: '0 4px 20px 0',
      padding: '0.857rem 0.857rem 0.857rem 1.143rem',
      cursor: 'pointer',
    },
    label: {
      fontWeight: '700',
      color: 'var(--primary-color)',
    },
  },
  active: {
    container: {
      width: 'auto',
      whiteSpace: 'nowrap',
      border: 'unset',
      borderRadius: '2.286rem',
      color: 'var(--neutral-white)',
      backgroundColor: 'var(--primary-color)',
      margin: '0 4px 20px 0',
      padding: '0.857rem 0.857rem 0.857rem 1.143rem',
      cursor: 'pointer',
    },
    label: {
      fontWeight: '700',
    },
  },
};
