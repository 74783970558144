import { GvEnvironment } from '@pv-frontend/gift-voucher-journey';

export interface EnvInterface extends GvEnvironment {
  production: boolean;
  clientId: string;
  regularProjectId: string;
  exclusiveProjectId: string;
  premierProjectId: string;
  apiUrlBsV1: string;
  apiUrlBsV1Internal: string;
  apiUrlLsV1: string;
  corePath: string;
  corePathV2: string;
  offerPath: string;
  offerPathV2: string;
  countryId: string;
  campaignPath: string;
  campaignEnterprisePath: string;
  urlsToBeRemoved: string[];
  apiBookingServiceUrlV2: string;
  sentryDsn: string;
}

// TODO: rename the variables with proper convention, blocker for this is razorpay-pg lib.
export const environment: EnvInterface = {
  production: true,
  apiPerksServiceUrl: 'https://api.poshvine.com/ps/v1',
  apiLoyaltyServiceUrl: 'https://api.poshvine.com/ls/v1',
  apiPerksServiceUrlV2: 'https://api.poshvine.com/ps/v2',
  apiClientsServiceUrl: 'https://api.poshvine.com/cs/v1',
  apiUrlPaV1: 'https://api.poshvine.com/pa/v1/',
  apiBookingServiceUrl: 'https://api.poshvine.com/bs/v1',
  clientId: 'f85c0595-ded9-4036-a751-68e2a44317e4',
  regularProjectId: '2ab8d7f1-4a55-4801-8121-9002de7d5b3f',
  exclusiveProjectId: 'bdf11ef4-e3ee-417b-b350-a66dd6d08c42',
  premierProjectId: 'a5932a7b-6e62-499a-bdac-c747ce3f7cc3',
  // clientId: '09fc67f1-b0f7-486e-9ef4-54cb6b74e89f',
  // projectId: '532792e1-c583-4437-b6e0-a5827597568b',
  apiUrlBsV1: 'https://api.poshvine.com/bs/v1/',
  apiUrlBsV1Internal: 'https://api.poshvine.com/bs/internal',
  apiUrlLsV1: 'https://api.poshvine.com/ls/v1/',
  corePath: 'https://sandbox-offerservice.poshvine.com/core_service/v1',
  corePathV2: 'https://api.poshvine.com/cs/v1',
  offerPath: 'https://sandbox-offerservice.poshvine.com/offer_service/v1',
  offerPathV2: 'https://api.poshvine.com/ps/v1',
  countryId: '625a3e8e-e493-421a-8ece-609f10b37442',
  campaignPath: 'https://api.poshvine.com/cas/v1/',
  campaignEnterprisePath: 'https://api.poshvine.com/cas/enterprise/',
  urlsToBeRemoved: ['https://janabank.poshvine.com'],
  apiBookingServiceUrlV2: 'https://api.poshvine.com/bs/v1',
  // coreServicePath: 'https://sandbox-api.poshvine.com/core_service/v1',
  sentryDsn:
    'https://0e3b11f67a689aaa8fac137326b2d3fc@o515678.ingest.us.sentry.io/4507429247975424',
};

// production:
// clientId: 'efa07a16-273f-4fd0-9866-c1d42c5d0eac',
// projectId: 'd6f66808-6cd0-472c-954c-c571bcec90ba'
