<pv-header></pv-header>
<div class="md:px-8 md:py-6 px-4 py-4">
  <div class="font-bold pv-text-18px pv-pb-16px">Terms and conditions</div>
  Jana Bank Offers and Benefits is a platform only for display of benefits
  extended by Merchants to Jana Bank's Customers, based on rewards, milestones –
  card activation and monthly milestones. This platform purely for the
  convenience of its Customers to display the offers extended by Merchants to
  Jana Bank Credit Card card holders. The Products/Services are being offered
  solely by the Merchants and Jana Bank is not selling/rendering any of these
  Products/Services. Jana Bank is not undertaking the business of online retail
  trading or shopping by hosting this platform. Jana Bank will not earn any
  commission or any other fee upon its Customers viewing the offers made
  available by Merchants or purchasing/ availing of a Product/Service offered by
  Merchants, except the customary considerations for use of Jana Bank Credit
  Card facility. The Customer is free to purchase/avail them from any other
  stores/online platforms and by using any other payment mechanism. Jana Bank is
  merely facilitating the discovery and redemption of personalized offers to its
  Customers by providing this platform. By hosting the platform, Jana Bank is
  neither guaranteeing nor giving any warranty nor making any representation
  with respect to the offers made by Merchants. Jana Bank is neither endorsing
  the Merchants or any Product/Service nor is it responsible for
  sale/quality/features of the Products/ Services under the offer. Any
  Information on the Jana Bank Offers and Benefits website (or the section
  thereof to which you will be directed) whether about the Merchant or the
  Products/Services being offered have not been verified by Jana Bank. Jana Bank
  shall not be liable or responsible for any such information. Jana Bank will
  not bear any obligation or liability if a Customer purchases/avails of a
  Product/Service under an offer. If you choose to avail of the purchase/avail
  of a Product/Service, Jana Bank will neither be a party to nor in any manner
  concerned with such purchase/availment nor be liable or responsible for any
  act or omission of the Merchant or the Customer. All Product/Service related
  queries/complaints will be addressed by the respective Merchant only.
  <div class="pv-text-16px pv-py-16px font-semibold">
    General Terms of Use & Service
  </div>
  The website https://janabank.poshvine.com is powered and maintained by
  PoshVine on behalf of Jana Bank, a company incorporated and existing in
  accordance with the laws of India. The following terms and conditions apply to
  all visitors or users of this Website. Please read these Site Terms and
  Conditions carefully before using this website. By accessing or using this
  website you explicitly agree to comply with and be bound by these Site Terms
  and Conditions. When you access any of the sub-Site (whether belonging to an
  ‘associate’ of Company or otherwise) through this Site, then the sub-Site may
  have its own terms and conditions, which are specific to such sub-Site. These
  Terms and Conditions of use and any additional terms posted on this site
  together constitute the entire agreement between Company and you with respect
  to your use of this site.
  <div class="pv-text-16px pv-py-16px font-semibold">
    Website and its Contents
  </div>
  This site is only for your personal use. All materials provided on this web
  site, including but not limited to all text, logos, designs, service marks,
  trademarks, graphics, images, sounds, information, software, documents,
  downloads of any kind, products and services and the selection, arrangement
  and display thereof, are the copyrighted works of Poshvine and/ or its
  partner/Jana Bank. You shall not distribute exchange, modify, sell or transmit
  anything you copy from this Site for any business, commercial or public
  purpose. Any unauthorized use of the content may violate copyright laws,
  trademark laws, the laws of privacy and publicity and civil and criminal
  statutes.
  <div class="pv-text-16px pv-py-16px font-semibold">General Provisions</div>
  Jana Bank / PoshVine may add, modify or remove any part of these Terms and
  Conditions of Use at any time as it may deem fit. Any changes to these Terms
  and Conditions of Use or any terms posted on this site will apply as soon as
  they are posted. By continuing to use this site after any changes are posted,
  you are indicating your acceptance of those changes. Jana Bank / PoshVine may
  add, change, discontinue, remove or suspend any other content posted on this
  site, including features and specifications of products or services described
  or depicted on the site, temporarily or permanently, at any time, without
  notice and without liability. Jana Bank/ PoshVine reserves the right to
  undertake all necessary steps to ensure that the security, safety and
  integrity of Company's systems as well as its clients and users interests are
  and remain, well-protected. Towards this end, PoshVine may take various steps
  to verify and confirm the authenticity, enforceability and validity of orders
  placed by you.
</div>
