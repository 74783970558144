import { ContextualGlobalDisplayConfig } from '@pv-frontend/contextual-journey';
import { GiftVouchersConfig } from '@pv-frontend/gift-voucher-journey';

import {
  contextualDektopJourneyConfig,
  contextualMobileJourneyConfig,
} from './contextual';
import { giftVouchersConfig } from './gift-vouchers';
import { giftVouchersDesktopConfig } from './gift-vouchers-desktop';

export interface AllJourneyConfig {
  journey: GiftVouchersConfig;
  contextual: ContextualGlobalDisplayConfig;
}

export const allJourneyDetails: AllJourneyConfig = {
  journey:
    window.innerWidth < 768 ? giftVouchersConfig : giftVouchersDesktopConfig,
  contextual:
    window.innerWidth < 768
      ? contextualMobileJourneyConfig
      : contextualDektopJourneyConfig,
};
