import { OverrideStyleConfig } from '@pv-frontend/pv-shared-components/common/types';

export const redeemButtonStyle: OverrideStyleConfig = {
  width: '100%',
  minWidth: '160px',
  borderRadius: '2.2rem',
  boxShadow: '0px 2px 8px 0px var(--button-box-shadow)',
  textTransform: 'uppercase',
  fontSize: '17px',
  height: '60px',
};
